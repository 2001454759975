import { createBrowserHistory } from 'history'
// import { HocuspocusProvider } from '@hocuspocus/provider'
//@ts-ignore
// import { IndexeddbPersistence } from 'y-indexeddb'
import posthog from 'posthog-js'
import ReactGA from 'react-ga4'
import {
  nid,
  values,
  jget,
  later,
  getParam,
  getParams,
  Base64,
  post,
} from './lib/utils'
import { bind } from './immer-yjs'
import { lg } from './log'
import { hny, _global } from './init'
import store from './Store'
import Keys from './keys'

import getSession, {
  setSession,
  refreshAuthSession,
  getProfile,
  clearSession,
  saveTeam,
} from '@/session'
import { db, supabase } from '@/db'
import { toast } from 'sonner'
import _ from 'lodash'
import { io } from 'socket.io-client'

export const history = createBrowserHistory()

export default class Sync {
  // static provider;
  static session
  static binder
  static ystate
  static connection_toast_id
  static socket
  static last_status = 'disconnected'
  static room
  static lb_provider
  static connection_id
  static local_provider
  static undoManager
  static client_session_id
  static con_error_showing
  //dont belong here
  static audio

  static get teams() {
    return (
      Sync.session?.profile?.teams
        ?.filter((t) => t)
        .filter((t) => t.indexOf('share-') == -1) || []
    )
  }

  static con_error() {
    lg('error', { error: 'connection' }, store)
    Sync.con_error_showing = true
    toast.loading('Disconnected, attempting to reconnect...', {
      id: 'connection',
      duration: Infinity,
      onDismiss: () => {
        Sync.con_error_showing = false
      },
      onAutoClose: () => {
        Sync.con_error_showing = false
      },
      action: {
        label: '↻ Reload',
        onClick: () => {
          window.location.reload()
        },
      },
    })
  }

  static async createUserAndTeam(data = {}, session = Sync.session) {
    store.init(false)
    store.initFromSession(
      _.omit(session, ['profile', 'access_token']),
      session.anonymous,
      data,
    )
    delete session.is_new
    await db.sessions.put(session)
  }

  static async syncBrowserLocationToStore() {
    const loc = location.pathname + location.search
    if (
      store.user &&
      (loc != store.user.location || store.user.host != location.host)
    ) {
      store.nav(loc, true, location.host)
    }
  }

  static getSocket(session) {
    return io(import.meta.env.VITE_API_URI, {
      auth: {
        token: session?.access_token || null,
        id: session.id,
        scope_id: id_from_url(),
        team_id: session.team_id,
        anon: session.anonymous,
        email: session.email,
        transports: ['websocket', 'polling'],
        off: getParam('off'),
      },
    })
  }

  static async connect_socket(session) {
    if (_global.socket) _global.socket.disconnect()
    _global.socket = Sync.socket = Sync.getSocket(session)

    return new Promise((resolve, reject) => {
      Sync.socket.on('connect', () => {
        store.connection_id = Sync.socket.id
        Sync.last_status = 'connect'

        if (Sync.con_error_showing)
          toast.success('Connected', {
            id: 'connection',
            action: null,
            duration: 900,
          })
        lg('error', { error: 'connection' }, store)
        console.log(`:: socket connected`)
        resolve('ok')
      })

      Sync.socket.on('serverActions', (action) => {
        later(() => store.applyAction(action))
      })

      Sync.socket.on('connect_error', (e) => {
        Sync.last_status = 'connect_error'
        later(() => {
          if (Sync.last_status == 'connect') return
          Sync.con_error()
        }, 4000)
      })

      Sync.socket.on('disconnect', () => {
        Sync.last_status = 'disconnect'
        later(() => {
          if (Sync.last_status == 'connect') return
        }, 2000)
      })
    })
  }

  static async loadShare(session) {
    var share_data = await jget(`/share/${session.share_id}`)

    if (share_data == 'error') {
      Sync.con_error()
      return
    }
    store.bootstrap(share_data)
    //add anon user to share session, can we skip this?
    store.setUser(
      _.omit(session, ['profile', 'access_token', 'location', 'seen']),
    )
    Sync.syncBrowserLocationToStore()
  }

  static async reloadServerStore(session) {
    return await jget(`/bootstrap/${session.id}/${id_from_url()}`)
  }

  static async initStore(session) {
    store.init(false)
    var data = await jget(`/bootstrap/${session.id}/${id_from_url()}`)
    if (data == 'error' || data == 'Forbidden') {
      Sync.con_error()
      return data
    }
    store.setCurrentUser(session.id)
    store.trigger((action, meta) => {
      //FIXME: the update/bootstrap cases is an ugly wart and potentially drops stuff
      if (
        !meta.external &&
        action.patches?.length &&
        action.name !== 'update' &&
        action.name !== 'bootstrap'
      ) {
        Sync.sendActions([action], meta.user_id)
      }
    }, 'send_client_action')
    store.bootstrap(data)
    return data
  }

  static async reconnect() {
    console.log('reconnect..')
    if (Sync.shouldAbortReconnect()) return

    const newSession = await Sync.validateSession()
    if (!newSession) return

    const anonSession = await Sync.getAnonSession()

    await Sync.setupSessionConnection(newSession, anonSession)
    await Sync.postConnectionSetup(newSession, anonSession)
  }

  // Retrieve an anonymous session
  static async getAnonSession() {
    return await getSession(true)
  }

  // Check initial conditions to see if reconnect should proceed
  static shouldAbortReconnect() {
    return store._confirm_email
  }

  // Validates the current session against a new one
  static async validateSession() {
    const newSession = await getSession()
    if (!Sync.session?.as && newSession.id == Sync.session?.id) {
      console.log(':: same session')
      return null
    }
    Sync.session = newSession
    return newSession
  }

  // Handles setting the session as something and connecting the socket
  static async setupSessionConnection(newSession, anonSession) {
    const as = getParam('as')
    if (as) await Sync.setSessionAs(as)
    await Sync.connect_socket(newSession)
  }

  // Perform actions after the connection is established
  static async postConnectionSetup(newSession, anonSession) {
    if (!newSession.share_id) {
      await Sync.handleUserSession(newSession, anonSession)
    } else {
      Sync.loadShare(newSession)
    }
    Sync.logSessionData()
  }

  // Handles scenarios for sessions without a shared ID
  static async handleUserSession(newSession, anonSession) {
    const data = await Sync.initStore(newSession)
    if (['error', 'Forbidden'].includes(data)) return

    if (!store.team?.id) {
      await Sync.handleNewUser(newSession, anonSession, data)
    } else if (!store.user?.id) {
      Sync.updateUserSession(Sync.session)
    }

    Sync.recordSessionParams(newSession, anonSession)
    store.postBootstrap()
    store.unshowAll()
    Sync.trackFunnelStep(newSession)

    Sync.handleToggleParams()
  }

  // Handles the creation of a new user and their team if required
  static async handleNewUser(newSession, anonSession, data) {
    if (newSession.anonymous) {
      // Handle anonymous user team creation
      await Sync.createUserAndTeam(data)

      // dark mode
      Sync.setUserPreferences()
      return
    }

    //>> Handle new user signups

    Object.assign(anonSession, {
      email: newSession.email,
      access_token: newSession.access_token,
      anonymous: false,
    })

    newSession = anonSession
    await post('/associate_user', { uid: newSession.id })
    await refreshAuthSession(true)
    newSession.access_token = _global.session.access_token
    Sync.session = newSession
    setSession(newSession)
    await Sync.connect_socket(newSession)
    const anonData = await Sync.initStore(newSession)
    if (!store.team?.id) await Sync.createUserAndTeam(data)

    // Update user in store
    store.updateUser({
      email: newSession.email,
      anonymous: false,
      authed_id: _global.session.user.id,
    })

    Sync.logNewSignup()
  }

  // Set user preferences
  static setUserPreferences() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      store.toggleProp('dark_mode', true)
    }
  }

  // Logs new user signups
  static logNewSignup() {
    ReactGA.gtag('event', 'conversion', {
      action: 'conversion',
      category: 'Conversion',
      send_to: 'AW-11465219128/pb72CIG-6oYZELi4hdsq',
    })
    lg('funnel', { step: 'new_signup' }, store)
  }

  // Updates user data in the session
  static updateUserSession(session) {
    store.setUser(
      _.omit(session, ['profile', 'access_token', 'location', 'seen']),
    )
    Sync.syncBrowserLocationToStore()
  }

  // Record session parameters for analytics or other tracking purposes
  static recordSessionParams(newSession, anonSession) {
    const mergedParams = _.merge(
      newSession.params,
      anonSession.params,
      getParams(),
      {
        annon_id: anonSession.annon_id,
      },
    )
    store.recordUserURLParams(mergedParams)
  }

  // Log session data for debugging or analytics
  static logSessionData() {
    const data = {
      ..._.pick(store.user, [
        'email',
        'anonymous',
        'team_id',
        'name',
        'create_time',
        'update_time',
      ]),
      ..._.mapKeys(
        store.user?.secure.params || {},
        (_, key) => `params_${key}`,
      ),
      ..._.mapKeys(store.user?.secure.plan || {}, (_, key) => `plan_${key}`),
    }
    lg('login', data, store)
    posthog.identify(store.user?.id, data)
  }

  // Track the user's progress through the signup funnel
  static trackFunnelStep(session) {
    const step = session.anonymous ? 'anon_signin' : 'signin'
    lg('funnel', { step: step }, store, true)
  }

  // Handle toggle params and clear them if necessary
  static handleToggleParams() {
    if (getParam('t')) {
      store.toggle(getParam('t'))
    }
    if (store.user?.id) clearParams()
  }
  static async setSessionAs(as) {
    const user = await jget('/auth/' + as)
    Sync.session.oid = Sync.session.id
    Sync.session.as = true
    Sync.session.id = user._id
    Sync.session.email = user.email
    Sync.session.anon = false
    Sync.session.team_id = null
  }

  static async runAsync(action_fn, session = null) {
    let socket = null
    if (!session) {
      socket = Sync.socket
    } else {
      socket = Sync.getSocket(session)
    }

    var action = await store.run(action_fn, true)

    return new Promise(async (resolve, reject) => {
      console.log(':: sending client action', action.name)
      if (socket.connected) {
        await socket.emitWithAck('clientActions', [action])
        resolve('ok')
      } else {
        console.log(':: not connected, queueing action')
        socket.once('connect', async () => {
          console.log(':: connected, sending queued action')
          await socket.emitWithAck('clientActions', [action])
          resolve('ok')
        })
      }
    })
  }

  static async sendActions(actions, sender_id) {
    console.log(':: sending client action', actions[0].name)
    if (Sync.session.as) return
    if (Sync.socket.auth.id != sender_id) {
      console.warn(
        ':: not sending mismatching action',
        actions[0].name,
        'from',
        sender_id,
      )
      return
    }
    if (Sync.socket.connected) {
      Sync.socket.emit('clientActions', actions)
    } else {
      console.log(':: not connected, queueing action')
      Sync.socket.once('connect', () => {
        console.log(':: connected, sending queued action')
        Sync.socket.emit('clientActions', actions)
      })
    }
    // later(() => Sync.socket.emit('clientActions', actions))
  }

  static async refreshProfile() {
    Sync.session.profile = await getProfile(Sync.session.email)
    if (!_.isEqual(store.profile, Sync.session.profile))
      store.setProfile(Sync.session.profile)
  }

  static async setTeam(team) {
    store.unshowAll()
    await saveTeam(team)
    // Sync.reconnect();
  }

  static async resetLocalDB(name = 'default') {
    if (!name) return
    if (_global.indexedDB) {
      await clearSession()
      var request = window.indexedDB.deleteDatabase(name)
      request.onerror = function () {
        console.log('Error deleting database.')
      }
      request.onsuccess = function () {
        location.reload()
      }
    }
  }
}

function id_from_url() {
  var id = window.location?.pathname?.split?.('/').at?.(2)
  return id ?? ''
}

function clearParams() {
  const url = new URL(window.location.href)
  url.search = ''
  const newUrl: string = url.toString()
  window.history.pushState({}, '', newUrl)
}

store.watch(
  (store) => store.user?.location,
  (a) => {
    //this monstrosity has got to go
    const loc = window.location.pathname + window.location.search
    const is_init =
      a.name == 'bootstrap' ||
      a.name == 'initFromSession' ||
      a.name == 'setUser'
    if (store.user?.location?.includes('/publish/')) store.collapseRight(false)
    // what was this for?
    // if (store.user && is_init) {
    //   if (window.location.pathname !== '/') {
    //     store.nav(loc)
    //   }
    // }
    if (!is_init && store.user?.location && store.user?.location !== loc) {
      history.push(store.user?.location)
    }
  },
)

history.listen((location) => {
  Sync.syncBrowserLocationToStore()
})

// // when team first added to store, set user to session
// store.watch(
//   (s) => s.team.id,
//   () => {if(store.team.id) store.addToTeam(store.user.id)}
// )

store.watch(
  (s) => s.user?.show?.download_app,
  () => {
    if (
      store.user?.show?.download_app &&
      location.pathname + location.search !== '/'
    ) {
      if (store.user?.anonymous && !store.current_persona) {
        setTimeout(
          () =>
            toast.warning(
              //title
              'You are not signed in.',
              {
                //description
                description:
                  'Sign up or sign in to save your conversations, see your chat history, and more.',
                action: {
                  label: '➥ Sign Up',
                  onClick: () => {
                    store.toggle('space_login')
                  },
                },
                id: 'sign_in',
                duration: 9000,
              },
            ),
          2000,
        )
      } else if (_global.os == 'ios') {
        setTimeout(
          () =>
            toast('Add Vello Mobile App for quick access.', {
              action: {
                label: 'Add',
                onClick: () => {
                  store.toggle('_show_home_screen')
                },
              },
              duration: 9000,
            }),
          2000,
        )
      } else if (
        _global.web &&
        (_global.os == 'mac' || _global.os == 'windows')
      ) {
        setTimeout(
          () =>
            toast.info(
              'Download Vello for Desktop to get the full experience.',
              {
                action: {
                  label: 'Download',
                  onClick: () => {
                    store.toggle('download')
                  },
                },
                duration: Infinity,
              },
            ),
          2000,
        )
      }
      store.toggle('download_app')
    }
  },
)

store.watch(
  (store) => store.state,
  (a) => {
    if (
      a.name == 'bootstrap' ||
      a.name == 'initFromSession' ||
      a.name == 'setUser'
    )
      return
    var event = hny.newEvent()
    lg('action', { action_name: a.name, action_args: a.args })
  },
  'action_log',
)

var nCounter = 0

var focus = () => {
  store?.setFocused(true)
}
var blur = () => store?.setFocused(false)
// // Set up event handler to produce text for the window focus event
window.removeEventListener('focus', focus)
window.removeEventListener('blur', blur)

window.addEventListener('focus', focus, false)

window.addEventListener('blur', blur, false)
;(window as any).Sync = Sync

var last_signed_in = null

supabase.auth.onAuthStateChange((e, session) => {
  if (e == 'PASSWORD_RECOVERY') store._password_recovery = true
  if (e == 'USER_UPDATED') store.setPasswordRecovery(false)
  if (!Sync.session?.share_id) {
    if (e == 'SIGNED_OUT') {
      posthog.reset()
      toast.success('Signed Out')
      last_signed_in = null
    }
    if (e == 'SIGNED_IN') {
      if (getParams().confirm) store.setConfirmEmail(true)
      else if (session.user.email !== last_signed_in) {
        // toast.success('Signed in as ' + session.user.email)
        last_signed_in = session.user.email
      }
    }
    if (!store._confirm_email) Sync.reconnect()
  }
  lg('auth', { event: e, session: session }, store)
})
