import * as React from 'react'

import { cn } from '@/lib/utils'
import TextareaAutosize from 'react-textarea-autosize'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, any>(
  ({ className, ...props }, ref) => {
    return (
      //@ts-ignore
      <TextareaAutosize
        className={cn(
          'scroll-py-2 resize-none flex w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-slate-300 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        // onHeightChange={(height, instance) => {
        //   console.log('instance', instance);
        //  console.log(height);
        // }}
        ref={ref}
        //@ts-ignore
        enterKeyHint="send"
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
