import * as z from 'zod'
export const JsonValueSchema = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.literal(null),
    z.record(z.lazy(() => JsonValueSchema.optional())),
    z.array(z.lazy(() => JsonValueSchema)),
  ]),
)
export const usersSchema = z.object({
  id: z.string(),
  model: z.string().nullable(),
  show_bar: z.boolean().nullable(),
  use_doc: z.boolean().nullable(),
  about: z.string().nullable(),
  access: JsonValueSchema,
  access_token: z.string().nullable(),
  ai: z.boolean().nullable(),
  anon: z.boolean().nullable(),
  anonymous: z.boolean().nullable(),
  archived: z.boolean().nullable(),
  assistant: z.boolean().nullable(),
  avatar_url: z.string().nullable(),
  base: z.boolean().nullable(),
  builtin: z.boolean().nullable(),
  code_interpreter: z.boolean().nullable(),
  create_time: z.number().nullable(),
  created: z.number().nullable(),
  /**
   * Could not determine type: the field only had null or empty values in the sample set.
   */
  current_message: JsonValueSchema,
  current_persona_id: z.string().nullable(),
  current_room: z.string().nullable(),
  current_room_id: z.string().nullable(),
  current_team: z.string().nullable(),
  dark_mode: z.boolean().nullable(),
  dense_mode: z.boolean().nullable(),
  description: z.string().nullable(),
  email: z.string().nullable(),
  focused: JsonValueSchema,
  helpers: z.string().array(),
  inactive: z.boolean().nullable(),
  instructions: z.string().nullable(),
  intro: z.string().nullable(),
  /**
   * Multiple data types found: Float: 99.4%, Boolean: 0.6% out of 346 sampled entries
   */
  is_focused: JsonValueSchema,
  is_new: z.boolean().nullable(),
  last_focused: z.number().nullable(),
  /**
   * Multiple data types found: Array(Float): 14.9%, Array(Int): 85.1% out of 572 sampled entries
   */
  layout: JsonValueSchema,
  location: z.string().nullable(),
  name: z.string().nullable(),
  oid: z.string().nullable(),
  other_assistants: z.boolean().nullable(),
  pinned: JsonValueSchema,
  provisional: z.boolean().nullable(),
  reload_requested: z.coerce.date().nullable(),
  retrieval: z.boolean().nullable(),
  seen: JsonValueSchema,
  shadow: z.boolean().nullable(),
  /**
   * Could not determine type: the field only had null or empty values in the sample set.
   */
  share_id: JsonValueSchema,
  subtitle: z.string().nullable(),
  team_id: z.string().nullable(),
  /**
   * Nested objects had no data in the sample dataset to introspect a nested type.
   */
  team_info: JsonValueSchema,
  type: z.string().nullable(),
  unpinned: z.boolean().nullable(),
  update_time: z.number().nullable(),
  updated: z.number().nullable(),
  use_web: z.boolean().nullable(),
  verbose_mode: z.boolean().nullable(),
  /**
   * Could not determine type: the field only had null or empty values in the sample set.
   */
  warning: JsonValueSchema,
})
