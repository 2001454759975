// suppress the stupid __spreadArray2 crap
;(function () {
  const originalConsoleError = console.error

  console.error = function (message, ...optionalParams) {
    if (
      typeof message === 'string' &&
      (message.includes('__spreadArray2 is not defined') ||
        message.includes('__spreadArray is not defined'))
    ) {
      // If you want to suppress the error, do nothing here.
    } else {
      // Otherwise call the original console.error function with all provided arguments
      originalConsoleError(message, ...optionalParams)
    }
  }
})()

import React from 'react'
import ReactDOM from 'react-dom/client'
import './init'
import App from './App.tsx'
import './index.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

if (import.meta.hot) {
  import.meta.hot.accept()
}
