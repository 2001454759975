import store from './Store'
import Sync from './sync'
import { _global } from './common_init'
import { configure } from 'react-hotkeys'
import { lg } from './log'
import { AiFillApple, AiFillWindows } from 'react-icons/ai'
import { Ava } from '@/App'
import copy from 'copy-to-clipboard'
import { clearSession } from '@/session'

import { FaHandSparkles } from 'react-icons/fa'
import { CgDockRight } from 'react-icons/cg'
import {
  Pencil1Icon,
  ChevronDownIcon,
  DoubleArrowLeftIcon,
  ChatBubbleIcon,
  EyeClosedIcon,
  CopyIcon,
  PersonIcon,
  BoxIcon,
  StopIcon,
  UpdateIcon,
  ResetIcon,
  KeyboardIcon,
  Share2Icon,
  DrawingPinIcon,
  SunIcon,
  FileIcon,
} from '@radix-ui/react-icons'

import { recent } from '@/lib/utils'

import { RiSideBarFill } from 'react-icons/ri'

import { values, keys, post, ofilter } from '@/lib/utils'
import { CiStickyNote } from 'react-icons/ci'
import {
  CaretDownIcon,
  CalendarIcon,
  EnvelopeClosedIcon,
  FaceIcon,
  GearIcon,
  StackIcon,
  PlusIcon,
  TrashIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ExitIcon,
  EnterIcon,
  RocketIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons'

import _ from 'lodash'
import { getFileName, later } from './lib/utils'

// capture the webpage
export async function share(share_type: string = 'chat') {
  const json = JSON.stringify(store.exportRoom(share_type))
  await post('/share', {
    screenshot: json,
    user: store.user,
    room: store.room.id,
  })
  var url = 'https://vello.ai/share/' + store.room?.id
  await copy(url)
  store.setToast({
    title: 'Share Link Copied to Clipboard',
    url,
    content: 'Share this link with anyone to view this space.',
  })
}

export class Commands {
  static _all_static: any = [
    {
      id: 'toggle_bar',
      sequence: 'cmd+k',
      handler: () => store.toggle('bar'),
    },
    {
      id: 'toggle_pricing_drawer',
      sequence: 'cmd+shift+p',
      handler: () => store.toggle('pricing_drawer'),
    },
    {
      id: 'toggle_pricing',
      sequence: 'cmd+option+p',
      handler: () => store.toggle('pricing_table'),
    },
    {
      id: 'what_is_vello',
      name: 'What is Vello?',
      icon: <QuestionMarkCircledIcon />,
      handler: () => store.toggle('help'),
    },
    {
      id: 'add_home',
      name: 'Add Vello App to Home Screen',
      on: () => !_global.desktop && _global.os == 'ios',
      icon: <AiFillApple />,
      handler: () => store.toggle('_show_home_screen'),
    },
    {
      id: 'get_vello_for_mac',
      name: 'Get Vello for Mac',
      on: () => !_global.desktop && _global.os == 'mac',
      icon: <AiFillApple />,
      handler: () => store.toggle('download'),
    },
    {
      id: 'vello_for_windows',
      name: 'Get Vello for Windows',
      on: () => !_global.desktop && _global.os == 'windows',
      icon: <AiFillWindows />,
      handler: () => store.toggle('download'),
    },
    {
      id: 'share',
      sequence: 'cmd+shift+s',
      type: 'list',
      on: () => !Sync.session?.share_id,
      name: 'Share',
      icon: <Share2Icon />,
      // handler: () => share('chat'),
    },
    {
      id: 'share_chat',
      sequence: 'cmd+shift+s',
      name: 'Share Chat',
      parent: 'share',
      on: () => !Sync.session?.share_id,
      icon: <Share2Icon />,
      handler: () => share('chat'),
    },
    {
      id: 'view_preferences',
      name: 'View Preferences',
      on: () => !store.user?.anonymous,
      sequence: 'alt+,',
      icon: <GearIcon />,
      handler: () => store.toggle('space_settings'),
    },
    {
      id: 'debug_history',
      name: 'Debug History',
      on: () => store.is_vello_admin,
      handler: () => store.toggle('debug_history'),
    },
    {
      id: 'debug_store',
      on: () => store.is_vello_admin,
      name: 'Debug Store',
      sequence: 'option+ctrl+d',
      handler: () => store.toggle('debug_store'),
    },
    {
      id: 'debug_user',
      on: () => store.is_vello_admin,
      name: 'Debug User',
      sequence: 'option+ctrl+p',
      handler: () => store.toggle('debug_user'),
    },
    {
      id: 'toggle_pin_app',
      name: 'Toggle Pin App to Top',
      sequence: 'ctrl+shift+p',
      on: () => _global.desktop,
      icon: <DrawingPinIcon />,
    },
    {
      id: 'toggle_desktop_quick',
      name: 'Show Desktop Quick Bar',
      sequence: 'ctrl+space',
      on: () => _global.desktop,
      icon: <FaHandSparkles />,
    },
    {
      id: 'toggle_desktop',
      name: 'Show Vello Desktop',
      sequence: 'option+space',
      on: () => _global.desktop,
      icon: <FaHandSparkles />,
    },
    {
      id: 'dock_vello_desktop',
      name: 'Dock Vello Desktop',
      sequence: 'ctrl+shift+space',
      on: () => _global.desktop,
      icon: <CgDockRight />,
    },
    {
      id: 'toggle_dark',
      name: 'Toggle Dark Mode',
      icon: <SunIcon />,
      handler: () => store.toggleProp('dark_mode', null, 'Dark Mode'),
    },
    {
      id: 'new_chat',
      sequence: 'ctrl+m',
      name: 'New Chat',
      icon: <PlusIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => store.addRoom(true, {}),
    },
    {
      id: 'change_members',
      sequence: 'ctrl+e',
      name: 'Edit Chat Members',
      icon: <PlusIcon />,
      on: () => !store.in_publish && !Sync.session?.share_id,
      handler: () => store.toggle('user_edit'),
    },
    {
      id: 'new_human_chat',
      sequence: 'alt+shift+n',
      name: 'New Empty Chat',
      icon: <PlusIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => store.addRoom(true, {}, null),
    },
    {
      id: 'duplicate_chat',
      sequence: 'option+shift+d',
      name: 'Duplicate Chat',
      icon: <CopyIcon />,
      on: () => !store.in_publish && store.room?.id && !Sync.session?.share_id,
      handler: () => store.duplicateRoom(),
    },
    {
      id: 'new_model',
      sequence: 'ctrl+shift+m',
      name: 'New Persona',
      icon: <PlusIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => {
        store.openNewModel()
      },
    },
    {
      id: 'teams',
      sequence: 'ctrl+option+s',
      name: 'Switch to Space..',
      icon: <StackIcon />,
      type: 'list',
      placeholder: 'Switch to Space...',
      on: () => !Sync.session?.share_id,
      // handler: () => store.toggle('teams'),
    },
    {
      id: 'talk',
      name: 'Talk to..',
      icon: <PersonIcon />,
      type: 'list',
      sequence: 'ctrl+option+m',
      placeholder: 'Talk to...',
      on: () => !Sync.session?.share_id,
    },
    {
      id: 'search_team_files',
      name: 'Search Team Files',
      icon: <FileIcon />,
      type: 'list',
      placeholder: 'Search Team Files',
      on: () => !Sync.session?.share_id,
    },
    {
      id: 'open_files',
      name: 'Open Files',
      icon: <FileIcon />,
      sequence: 'ctrl+option+f',
      handler: () => {
        store.nav('/docs')
      },
    },
    {
      id: 'room_models',
      name: 'Set Room Model..',
      sequence: 'option+m',
      icon: <PersonIcon />,
      type: 'list',
      on: () => !Sync.session?.share_id,
    },
    {
      id: 'add_to_teams',
      name: 'Add to Space..',
      icon: <StackIcon />,
      type: 'list',
      placeholder: 'Add to Space...',
      context: () => `Persona - ${store.user_name(Commands.context.target.id)}`,
      hidden: true,
      on: () => !Sync.session?.share_id,
      // handler: () => store.toggle('teams'),
    },
    {
      id: 'update_model',
      sequence: 'ctrl+option+m',
      name: 'Update Persona',
      icon: <PlusIcon />,
      on: () =>
        !Sync.session?.share_id &&
        store.model &&
        store.users[store.model] &&
        store.users[store.model].ai &&
        !store.users[store.model].builtin,
      handler: () => store.editModel(),
    },
    {
      id: 'previous_space',
      sequence: 'alt+k',
      name: 'Previous Chat',
      icon: <ArrowUpIcon />,
      handler: () => store.switchRoom(true),
    },
    {
      id: 'next_space',
      sequence: 'alt+j',
      name: 'Next Chat',
      icon: <ArrowDownIcon />,
      handler: (e) => {
        store.switchRoom()
      },
    },
    {
      id: 'archive_space',
      sequence: 'ctrl+shift+backspace',
      name: 'Archive Chat',
      icon: <TrashIcon />,
      on: () => !store.in_publish && !Sync.session?.share_id,
      handler: () => store.deleteRoom(),
    },
    {
      id: 'prev_model',
      sequence: 'ctrl+h',
      name: 'Previous Model',
      icon: <ArrowLeftIcon />,
      on: () => !store.in_publish && !Sync.session?.share_id,
      handler: () => store.switchModel(true),
    },
    {
      id: 'next_model',
      sequence: 'ctrl+l',
      name: 'Next Model',
      icon: <ArrowRightIcon />,
      on: () => !store.in_publish && !Sync.session?.share_id,
      handler: () => store.switchModel(),
    },
    {
      id: 'toggle_sidebar',
      sequence: 'ctrl+[',
      name: 'Toggle Sidebar',
      icon: <RiSideBarFill />,
      on: () => !Sync.session?.share_id,
      handler: () => {
        store.collapseLeft(store.user.layout[0] !== 0)
      },
    },
    {
      id: 'toggle_doc',
      sequence: 'ctrl+]',
      name: 'Toggle Notes',
      icon: <CiStickyNote />,
      handler: () => {
        store.collapseRight(store.user.layout[2] !== 0)
      },
    },
    {
      id: 'stop',
      sequence: 'ctrl+s',
      name: 'Stop Response',
      icon: <StopIcon />,
      handler: () => store.stopStreaming(),
    },
    {
      id: 'rewrite',
      sequence: 'ctrl+d',
      name: 'Rewrite Last Exchange',
      icon: <UpdateIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => store.rewriteLastMessage(),
    },
    {
      id: 'dupe',
      sequence: 'option+d',
      name: 'Load Last Query',
      icon: <UpdateIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => {
        store._user_edit = false
        store.reloadLastMessage()
      },
    },
    {
      id: 'clear',
      sequence: 'ctrl+shift+d',
      name: 'Clear Chat',
      icon: <TrashIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => store.clearRoom(),
    },
    {
      id: 'regen',
      sequence: 'ctrl+r',
      name: 'Regenerate Response',
      icon: <UpdateIcon />,
      on: () => !Sync.session?.share_id,
      handler: () => store.regenResponse(),
    },
    {
      id: 'rename_chat',
      on: () => !store.in_publish && !Sync.session?.share_id,
      icon: <UpdateIcon />,
      sequence: 'ctrl+shift+k',
      name: 'Rename Chat',
      context: () => `Chat - ${store.room_title(store.room.id)}`,
      type: 'text',
      load: () => store.room.title,
      save: (name) => store.renameRoom(name),
    },
    {
      id: 'toggle_pinned',
      sequence: 'ctrl+p',
      name: 'Toggle Pinned',
      icon: <DrawingPinIcon />,
      handler: () => store.togglePinned(),
    },
    {
      id: 'undo',
      sequence: 'ctrl+z',
      name: 'Undo',
      icon: (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.85355 2.14645C5.04882 2.34171 5.04882 2.65829 4.85355 2.85355L3.70711 4H9C11.4853 4 13.5 6.01472 13.5 8.5C13.5 10.9853 11.4853 13 9 13H5C4.72386 13 4.5 12.7761 4.5 12.5C4.5 12.2239 4.72386 12 5 12H9C10.933 12 12.5 10.433 12.5 8.5C12.5 6.567 10.933 5 9 5H3.70711L4.85355 6.14645C5.04882 6.34171 5.04882 6.65829 4.85355 6.85355C4.65829 7.04882 4.34171 7.04882 4.14645 6.85355L2.14645 4.85355C1.95118 4.65829 1.95118 4.34171 2.14645 4.14645L4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      handler: () => store.undo(),
    },
    {
      id: 'redo',
      sequence: 'ctrl+shift+z',
      name: 'Redo',
      icon: (
        <svg
          className="transform scale-x-[-1]"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.85355 2.14645C5.04882 2.34171 5.04882 2.65829 4.85355 2.85355L3.70711 4H9C11.4853 4 13.5 6.01472 13.5 8.5C13.5 10.9853 11.4853 13 9 13H5C4.72386 13 4.5 12.7761 4.5 12.5C4.5 12.2239 4.72386 12 5 12H9C10.933 12 12.5 10.433 12.5 8.5C12.5 6.567 10.933 5 9 5H3.70711L4.85355 6.14645C5.04882 6.34171 5.04882 6.65829 4.85355 6.85355C4.65829 7.04882 4.34171 7.04882 4.14645 6.85355L2.14645 4.85355C1.95118 4.65829 1.95118 4.34171 2.14645 4.14645L4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      handler: () => store.undo(false),
    },
    {
      id: 'share_note',
      sequence: 'cmd+shift+d',
      name: 'Share Note',
      parent: 'share',
      icon: <Share2Icon />,
      handler: () => share('doc'),
    },
    {
      id: 'share_both',
      sequence: 'cmd+shift+b',
      name: 'Share Both Chat & Note',
      parent: 'share',
      icon: <Share2Icon />,
      handler: () => share('both'),
    },
    {
      id: 'focus',
      sequence: 'cmd+i',
      name: 'Focus Input',
      handler: (e) => {
        store.user?.deep_mode
          ? Keys.editor?.commands.focus()
          : Keys.input?.current?.focus()
      },
    },
    {
      id: 'focus_s',
      sequence: 'cmd+/',
      name: 'Focus Search',
      handler: (e) => {
        Keys.search?.current?.focus()
      },
    },
    {
      id: 'verbose',
      sequence: 'option+v',
      name: 'Toggle Verbose Mode',
      handler: (e) => {
        store.toggleProp('verbose_mode', null, 'Verbose Mode')
      },
    },
    {
      id: 'writing',
      sequence: 'option+w',
      name: 'Toggle Writing Mode',
      handler: (e) => {
        store.toggleProp('deep_mode', null)
      },
    },
    {
      id: 'writing_horizontal',
      sequence: 'option+shift+w',
      name: 'Toggle Writing Mode Orientation',
      handler: (e) => {
        store.toggleProp('deep_mode_horizontal', null)
      },
    },
    {
      id: 'send_msg',
      sequence: 'option+enter',
      handler: (e) => {
        e.preventDefault()
        store._user_edit = false
        store.sendMessage()
      },
    },
    {
      id: 'login',
      // sequence: 'ctrl+shift+r',
      icon: <EnterIcon />,
      name: 'Sign In',
      handler: () => store.toggle('space_login'),
    },
    {
      id: 'logout',
      // sequence: 'ctrl+shift+r',
      on: () => store.user?.id,
      icon: <ExitIcon />,
      name: 'Sign Out',
      handler: () => clearSession(),
    },
    {
      id: 'reset_local',
      sequence: 'ctrl+shift+r',
      name: 'Reset Vello',
      handler: () => Sync.resetLocalDB(),
    },
    {
      id: 'toggle_archived',
      sequence: 'ctrl+shift+a',
      icon: <EyeClosedIcon />,
      name: 'Toggle Show Archived Chats',
      handler: () => store.toggle('archived'),
    },
    {
      id: 'archive_all',
      name: 'Archive All Chats',
      icon: <TrashIcon />,
      handler: () =>
        store.confirm(
          () => store.archiveAllChats(),
          'Are you sure you want to archived all chats?',
        ),
    },
    {
      id: 'clear_archived',
      name: 'Clear Archived Chats',
      icon: <TrashIcon />,
      handler: () =>
        store.confirm(
          () => store.clearArchivedRooms(),
          'Are you sure you want to clear all archived chats? This is irreversible.',
        ),
    },
    {
      id: 'toggle_keys',
      name: 'Toggle Keyboard Shortcuts',
      icon: <KeyboardIcon />,
      handler: () =>
        store.toggleProp('keys_off', null, 'Disable Keyboard Shortcuts'),
    },
    {
      id: 'esc',
      sequence: 'escape',
      handler: () => store.unshowAll(),
    },
    {
      id: 'twitter',
      sequence: 'ctrl+shift+t',
      handler: async () => {
        console.log('twitter')
        // console.log(await nango.auth('twitter', store.user.id))
        console.log('done')
      },
    },
  ]

  static get _teams() {
    return store.active_teams.map((t) => {
      return {
        id: 'add_to_' + t.id,
        icon: (
          <Ava
            className="leave_white text-white color-white shrink-0"
            width={7}
            user={store.teams[t.id]}
            is_team
          />
        ),
        // sequence: 'ctrl+shift+w',
        name: t.name ?? 'Your Space',
        parent: 'teams',
        handler: () => store.setCurrentTeam(t.id),
      }
    })
  }

  static get _add_to_teams() {
    return store.active_teams.map((t) => {
      return {
        id: t.id,
        icon: (
          <Ava
            className="leave_white text-white color-white shrink-0"
            width={7}
            user={store.teams[t.id]}
            is_team
          />
        ),
        // sequence: 'ctrl+shift+w',
        name: t.name ?? 'Your Space',
        parent: 'add_to_teams',
        handler: () => store.addToTeam(Commands.context.target.id, t.id),
      }
    })
  }

  static get _room_models() {
    return values(store.ai_users).map((m) => {
      return {
        id: 'switch_' + m.id,
        parent: 'room_models',
        icon: (
          <Ava
            className="leave_white text-white color-white shrink-0"
            width={7}
            pill
            user={m}
          />
        ),
        // sequence: 'ctrl+shift+w',
        name: 'Switch to ' + m.name,
        on: () => store.room?.model !== m.id,
        handler: () => {
          store.setModel(m.id)
        },
      }
    })
  }

  static get _models() {
    return values(store.ai_users).map((m) => {
      return {
        id: m.id,
        parent: 'talk',
        icon: (
          <Ava
            className="leave_white text-white color-white shrink-0"
            width={7}
            pill
            user={m}
          />
        ),
        // sequence: 'ctrl+shift+w',
        name: 'Talk to ' + m.name,
        context: () => `Ask ${m.name}`,
        type: 'text',
        load: () => '',
        save: (content) => {
          store.addRoom(true, {}, m.id)
          store.addMessage({ content })
        },
      }
    })
  }

  static get _files() {
    return values(store.files)
      .sort(recent)
      .filter((f) => f.type === 'file' && !f.archived && store.in_team(f))
      .map((file) => {
        const name = getFileName(file.id)
        return {
          id: 'file_' + file.id,
          parent: 'search_team_files',
          icon: <FileIcon />,
          name: 'Open file ' + name,
          context: () => `Opening ${name}`,
          handler: (_) => {
            store.setCurrentFile(store.room.id, file.id)
          },
        }
      })
  }

  static get _all() {
    return Commands._all_static
      .insertAt(13, Commands._models)
      .concat(Commands._teams)
      .concat(Commands._add_to_teams)
      .concat(Commands._room_models)
      .concat(Commands._files)
  }

  static get selected() {
    return Commands.all[store.state._sel_cmd]
  }

  static get _current() {
    if (store.state._sel_cmd) {
      var selected = [Commands.all[store.state._sel_cmd]]
      if (selected[0].type !== 'list') return selected
      return values(Commands.all).filter(
        (c) => c.parent == store.state._sel_cmd,
      )
    }
    return Commands._all.filter((c) => !c.parent && !c.hidden)
  }

  static get current() {
    return _.keyBy(
      Commands._current.filter((c) => !c.on || c.on()),
      'id',
    )
  }

  static get all() {
    return _.keyBy(
      Commands._all.filter((c) => !c.on || c.on()),
      'id',
    )
  }

  static get keyed(): any {
    return ofilter(Commands.current, (c) => c.sequence) as any
  }

  static get handlers(): any {
    return _.fromPairs(
      keys(Commands.keyed).map((k) => [
        k,
        (e, ...args) => {
          if (store.user.keys_off) return
          Commands.run(k, e, ...args)
        },
      ]),
    )
  }

  static run(id, e = null, context = null, ...args) {
    e?.preventDefault()
    if (context) Commands.context = context
    var cmd = Commands.all[id]

    lg('command', { id })

    if (!cmd) return
    if (cmd.type) {
      store.set('_sel_cmd', id)
      store.toggle('bar', true)
    } else {
      if (cmd.id != 'toggle_bar') {
        store.toggle('bar', false)
        store.toggle('desktop_bar', false)
      }
      store.set('_sel_cmd', null)
      return cmd?.handler(e, ...args)
    }
  }
}

_global.Commands = Commands

export default class Keys {
  static input
  static editor
  static search
  static did_right
  static did_left

  // static toggleLeft() {
  //   if (Keys.left.getCollapsed()) {
  //     if (true) {
  //       Keys.left.resize((300 / document.body.clientWidth) * 100)
  //     } else {
  //       Keys.left.expand()
  //     }
  //   } else {
  //     Keys.left.collapse()
  //   }
  //   Keys.did_left = true
  // }

  // static toggleRight() {
  //   if (store.is_mobile) {
  //     return
  //   }
  //   if (Keys.right.getCollapsed()) {
  //     if (!Keys.did_right) {
  //       Keys.right.resize(50)
  //       Keys.right.expand()
  //     } else {
  //       Keys.right.expand()
  //     }
  //   } else {
  //     Keys.right.collapse()
  //   }
  //   Keys.did_right = true
  // }

  static init() {
    if (_global.os == 'windows') {
      window.addEventListener('keydown', function (event) {
        if (event.ctrlKey) {
          switch (event.key) {
            // case '`':
            //   store.switch();
            //   break;
            case 'k':
              Commands.run('toggle_bar', event)
              event.preventDefault()
              break
            // case '[':
            //   Keys.toggleLeft();
            //   break;
            // case ']':
            //   Keys.toggleRight();
            //   break;
          }
        }
      })
    }
  }
}

Keys.init()
;(window as any).Keys = Keys

configure({
  /**
   * The level of logging of its own behaviour React HotKeys should perform.
   */
  logLevel: 'warn',

  /**
   * Default key event key maps are bound to (keydown|keypress|keyup)
   */
  defaultKeyEvent: 'keydown',

  /**
   * The default component type to wrap HotKey components' children in, to provide
   * the required focus and keyboard event listening for HotKeys to function
   */
  defaultComponent: 'div',

  /**
   * The default tabIndex value passed to the wrapping component used to contain
   * HotKey components' children. -1 skips focusing the element when tabbing through
   * the DOM, but allows focusing programmatically.
   */
  defaultTabIndex: '-1',

  /**
   * The HTML tags that React HotKeys should ignore key events from. This only works
   * if you are using the default ignoreEventsCondition function.
   * @type {String[]}
   */
  // ignoreTags: [],

  ignoreTags: ['input', 'select', 'textarea'],

  /**
   * The function used to determine whether a key event should be ignored by React
   * Hotkeys. By default, keyboard events originating elements with a tag name in
   * ignoreTags, or a isContentEditable property of true, are ignored.
   *
   * @type {Function<KeyboardEvent>}
   */
  ignoreEventsCondition: () => false,

  /**
   * Whether to ignore changes to keyMap and handlers props by default
   * (this reduces a significant amount of unnecessarily resetting
   * internal state)
   * @type {boolean}
   */
  ignoreKeymapAndHandlerChangesByDefault: true,

  /**
   * Whether to ignore repeated keyboard events when a key is being held down
   * @type {boolean}
   */
  ignoreRepeatedEventsWhenKeyHeldDown: false,

  /**
   * Whether React HotKeys should simulate keypress events for the keys that do not
   * natively emit them.
   * @type {boolean}
   */
  simulateMissingKeyPressEvents: true,

  /**
   * Whether to call stopPropagation() on events after they are
   * handled (preventing the event from bubbling up any further, both within
   * React Hotkeys and any other event listeners bound in React).
   *
   * This does not affect the behaviour of React Hotkeys, but rather what
   * happens to the event once React Hotkeys is done with it (whether it's
   * allowed to propagate any further through the Render tree).
   */
  stopEventPropagationAfterHandling: true,

  /**
   * Whether to call stopPropagation() on events after they are
   * ignored (preventing the event from bubbling up any further, both within
   * React Hotkeys and any other event listeners bound in React).
   *
   * This does not affect the behaviour of React Hotkeys, but rather what
   * happens to the event once React Hotkeys is done with it (whether it's
   * allowed to propagate any further through the Render tree).
   */
  stopEventPropagationAfterIgnoring: true,

  /**
   * Whether to allow combination submatches - e.g. if there is an action
   * bound to cmd, pressing shift+cmd will *not* trigger that action when
   * allowCombinationSubmatches is false.
   */
  allowCombinationSubmatches: true,

  /**
   * A mapping of custom key codes to key names that you can then use in your
   * key sequences
   */
  customKeyCodes: {},
})

// window.addEventListener('keydown', function(e) {
//   console.log('values(Commands.keyed).filter(c=>isHotkeyPressed(c.sequence))', values(Commands.keyed).filter(c=>isHotkeyPressed(c.sequence)));
//   console.log('isHotkeyPressed(c.sequence)', isHotkeyPressed('ctrl+l'));
//   values(Commands.keyed).filter(c=>isHotkeyPressed(c.sequence)).map(c=>Commands.run(c.id, e))
// });

// }
