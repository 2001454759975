import { clsx } from 'clsx'
import _ from 'lodash'
import { twMerge } from 'tailwind-merge'
import { customAlphabet } from 'nanoid'
import createSelector from 'rememo'
import axios from 'axios'
import {
  formatDistanceStrict,
  formatDistance,
  formatRelative,
  subDays,
} from 'date-fns'

import { _global } from '../common_init'
import { toast } from 'sonner'
import store from '../Store'

export const keys = Object.keys
//@ts-ignore
export const values = (x) => (x ? Object.values<any>(x) : [])
//@ts-ignore
export const entries = Object.entries

export const nid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789_', 8)

import dayjs from 'dayjs'
import relative from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(relative)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'now',
    m: '1 min',
    mm: '%d min',
    h: '1 hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

export const ol = (list) => (list && list.length ? list : [])
export const ob = (obj) => (obj && keys(obj).length ? obj : {})

export const minute = 60000
export const hour = 3600000
export const day = hour * 24
export const week = day * 7
export const month = day * 30
export const CDN_URL = 'https://d3cflkbt5y83mw.cloudfront.net'

var timers = {} // Object to hold timers by ID

export function parse(text) {
  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}

export function later(fn, delay = 0, id = null) {
  // If there is already a timer with this ID, clear it.
  id = id ?? nid()
  if (timers[id]) {
    clearTimeout(timers[id])
  }

  // Set the new timer and store its ID using the provided id as the key.
  timers[id] = setTimeout(function () {
    // Remove the timer ID from the object as its job is done after execution.
    delete timers[id]
    // Execute the callback function.
    fn()
  }, delay)
}
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export function plural(name) {
  return name + 's'
}

export function rel(date) {
  return formatDistanceStrict(date, new Date(), { addSuffix: true })
}

export function ago(date) {
  return dayjs(date).fromNow()
}
_global.ago = ago
_global.rel = ago

export function env(key) {
  //@ts-ignore
  if (_global.S) {
    return process.env[key]
  } else {
    return import.meta.env[key]
  }
}

export async function query(q) {
  return await jget(`/query?${objectToQueryString(q)}`)
}

function objectToQueryString(obj) {
  const keyValuePairs = []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      keyValuePairs.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]),
      )
    }
  }
  return keyValuePairs.join('&')
}

var cache = {}
export async function jget(url, msg = null, fetch_fn = null) {
  try {
    // if (cache[url]) return cache[url]
    let r = await get(url, msg, fetch_fn)
    if (r.status && r.status != 200) {
      return r.statusText
    }
    cache[url] = await r.json()
    return cache[url]
  } catch (e) {
    if (!msg)
      toast.error('Issue reaching server. Please try again later.', {
        id: 'connection',
      })
    else toast.error('Issue while ' + msg + '.')
    return 'error' // why u no object
  }
}

export async function get(url, msg = null, fetch_fn = null) {
  try {
    let r = await ftch('GET', url, (fetch_fn = null))
    if (msg) {
      toast.success('Finished ' + msg + '.')
    }
    return r
  } catch (e) {
    console.error(`get:`, e)
    if (!msg)
      toast.error('Issue communicating with server. Please try again later.', {
        id: 'connection',
      })
    else toast.error('Issue while ' + msg + '.')
    return 'error'
  }
}

_global.get = get

export async function post(url, data = {}, msg = null, fetch_fn = null) {
  try {
    let r = await ftch('POST', url, data, (fetch_fn = null))
    if (r.status && r.status != 200) {
      if (msg) {
        toast.error('Issue while ' + msg + '.')
      }
      return r
    }
    if (msg) {
      toast.success('Done ' + msg + '.')
    }
    return r
  } catch (e) {
    if (!msg)
      toast.error('Issue communicating with server. Please try again later.')
    else toast.error(' Issue while ' + msg + '.')
  }
}

export const KEY_MOD = _global.os === 'windows' ? '⌃' : '⌘'
export const OPTION_MOD = _global.os == 'windows' ? 'Alt' : '⌥'
export function key_to_symbol(key) {
  return toCapitalCase(key)
    .replace('Cmd', KEY_MOD)
    .replace('Ctrl', '⌃')
    .replace('Alt', OPTION_MOD)
    .replace('Option', OPTION_MOD)
    .replace('Shift', '⇧')
    .trim()
}

export async function ftch(method, url, data, fetch_fn = null) {
  var headers = {}
  if (_global.Sync.session?.access_token) {
    headers = { Authorization: _global.Sync.session.access_token }
  }
  headers = { ...headers, 'X-User-Id': _global.Sync.session?.id }
  url = url.startsWith('http') ? url : env('VITE_API_URI') + url
  fetch_fn = fetch_fn ?? fetch
  var request: any = {
    method: method,
    // TODO: need to include to pass sticky flags via url to server
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }
  if (method == 'POST') request.body = JSON.stringify(data)
  console.log('url:', url)
  return fetch_fn(url, request)
}

export function downloadURI(uri, name) {
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadMarkdown(markdownText, fileName) {
  const element = document.createElement('a')
  const file = new Blob([markdownText], { type: 'text/markdown' })
  element.href = URL.createObjectURL(file)
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export function getFileName(id) {
  // could also look in store.docs and store.files and determine type that way
  // faster because you wouldn't have to do lodash merge
  var all_files = store.all_files()
  if (!id || !all_files[id]) {
    return ''
  }
  const file = all_files[id]
  return (
    file.name ??
    file.filename ??
    (file.content && file.content.length > 0
      ? file.content.substring(0, 15) + (file.content.length > 15 ? '...' : '')
      : null) ??
    'File ' + id
  )
}

var numberMap = {
  '[object Boolean]': 2,
  '[object Number]': 3,
  '[object String]': 4,
  '[object Function]': 5,
  '[object Symbol]': 6,
  '[object Array]': 7,
}
var __type = Object.prototype.toString
function typeNumber(data) {
  if (data === null) {
    return 1
  }
  if (data === void 666) {
    return 0
  }
  var a = numberMap[__type.call(data)]
  return a || 8
}

var hasOwnProperty = Object.prototype.hasOwnProperty

export function shallowEqual(objA, objB) {
  if (Object.is(objA, objB)) {
    return true
  }
  if (typeNumber(objA) < 7 || typeNumber(objB) < 7) {
    return false
  }
  var keysA = Object.keys(objA)
  var keysB = Object.keys(objB)
  if (keysA.length !== keysB.length) {
    return false
  }
  for (var i = 0; i < keysA.length; i++) {
    if (
      !hasOwnProperty.call(objB, keysA[i]) ||
      !Object.is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false
    }
    // var a = objA[keysA[i]];
    // var b = objB[keysA[i]];
    // if (typeof(a) === 'function' && typeof(b) === 'function') {
    //   if (a() !== b()) return false;
    // }
  }
  return true
}

export const is = shallowEqual

export function toCapitalCase(str) {
  if (!str) return ''
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  return str.split(' ').map(capitalizeFirstLetter).join(' ')
}

export function cn(...inputs: any[]) {
  return twMerge(clsx(inputs))
}

export const extract = (str, regex) => (str.match(regex) || [''])[1]
export const array_to_obj = (array) =>
  array.reduce((acc, value, index) => ({ ...acc, [index]: value }), {})

export function insert(array, element, pos = -1) {
  if (pos < 0) pos = array.length + pos + 1
  array.splice(pos, 0, element)
  return array
}

export const now = () => new Date().toISOString()

export function get_patches(text) {
  const regex = />>>([\s\S]*?)(?=>>>|$)/g
  const blocks = []
  let match

  while ((match = regex.exec(text)) !== null) {
    blocks.push(match[1].trim())
  }
  return blocks
}

export function ofilter(obj, fn) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => fn(value, key)),
  )
}

export class Omap {
  constructor(initialArray = []) {
    this.keys = []
    this.data = {}
    this.maxId = -1 // keep track of the maximum ID assigned
    initialArray.forEach((item, index) => {
      this.keys.push(index)
      this.data[index] = item
      this.maxId = index
    })
  }

  insert(items, id, insertAfter = true) {
    const position = this.keys.indexOf(id) + (insertAfter ? 1 : 0)

    items.reverse().forEach((item) => {
      this.maxId++ // increment the maximum ID
      this.keys.splice(position, 0, this.maxId)
      this.data[this.maxId] = item
    })
  }

  log() {
    this.keys.map((k) => console.log(k + '| ' + this.data[k]))
  }

  get(id) {
    return this.data[id]
  }

  getAll() {
    return _.map(this.keys, (key) => this.data[key])
  }

  getLastId() {
    return this.keys[this.keys.length - 1]
  }

  getByPosition(position) {
    let key = this.keys[position]
    return this.data[key]
  }

  delete(id) {
    _.pull(this.keys, id)
    delete this.data[id]
  }
}

export function getParams() {
  var params: any = Object.fromEntries(
    new URLSearchParams(window.location.search),
  )
  if (
    document.referrer &&
    document.referrer !== window.location.href &&
    !document.referrer.includes('tagassistant.google') &&
    !document.referrer.includes('vello.ai') &&
    !document.referrer.includes('accounts.google.com') &&
    !document.referrer.includes('stripe.com')
  ) {
    var referrer = new URL(document.referrer).origin
    var referrerParams = Object.fromEntries(
      new URL(document.referrer).searchParams,
    )
    params = {
      ...params,
      referrer,
      referrerParams,
      referrerFull: document.referrer,
    }
  }
  return params
}

export function getParam(name) {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(name)
}

export function setSubdomain(subdomain, uri) {
  // Create a URL object for easy manipulation
  var url = new URL(uri)

  // Get the hostname from the URL
  var hostname = url.hostname

  // Split the hostname into parts (subdomains, domain, TLD)
  var parts = hostname.split('.')

  // Check if the current subdomain is 'www' or if it's a subdomain
  // If it is, we'll replace the first part, else we prepend
  if (parts.length > 2 && parts[0] !== 'www') {
    // Replace the first part with 'hello'
    parts[0] = subdomain
  } else {
    // Else, there is no subdomain or it's 'www', so we insert at the beginning
    parts.unshift(subdomain)
  }

  // Recombine the hostname parts
  var newHostname = parts.join('.')

  // Assign the new hostname back to the URL object
  url.hostname = newHostname

  // Get the new URL with the subdomain
  var newUrl = url.toString()
  return
}

export function getSubdomain(host) {
  if (!host) return
  var domains = host.split('.')
  return domains
    .filter((x) => x != 'ai' && x != 'vello' && !x.startsWith('localhost'))
    .at(0)
}

export function omit_null(obj) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      omit_null(obj[key])
    }
  }
}

export const Base64 = {
  // binary to base64
  encode: function (str) {
    if (!str) return null
    return _global.S
      ? Buffer.from(str).toString('base64')
      : btoa(unescape(encodeURIComponent(str)))
  },

  // base64 to binary
  decode: function (str) {
    if (!str) return null
    return _global.S
      ? Buffer.from(str, 'base64').toString()
      : decodeURIComponent(escape(atob(str)))
  },
}

export const renameKeys = (object, newKeys) => {
  return _.mapKeys(object, function (value, key) {
    return newKeys[key] || key
  })
}

export function get_elem_value(id) {
  const elem = document.getElementById(id) as any
  return elem?.value
}

export function latest(a, b) {
  return a.create_time < b.create_time ? 1 : -1
}

export function recent(a, b) {
  return a.update_time < b.update_time ? 1 : -1
}

//@ts-ignore
String.prototype.trunc = function (n) {
  return this.substr(0, n - 1) + (this.length > n ? '…' : '')
}

//@ts-ignore
String.prototype.undot = function (n) {
  return this.replaceAll('.', '__')
}

//@ts-ignore
Array.prototype.insertAt = function (index, list) {
  return _.concat(_.slice(this, 0, index), list, _.slice(this, index))
}

//@ts-ignore
Array.prototype.upsert = function (item, key = 'id') {
  const index = _.findIndex(this, { [key]: item[key] })

  if (index >= 0) {
    // Modify the existing element.
    this[index] = item
  } else {
    // Add the new element.
    this.push(item)
  }
}

export function DefaultObject(defaultValue) {
  return new Proxy(
    {},
    {
      get: function (target, prop) {
        if (!target.hasOwnProperty(prop)) {
          target[prop] =
            typeof defaultValue === 'function' ? defaultValue() : defaultValue
        }
        return target[prop]
      },
    },
  )
}

export function preprocessForRemarkMath(text) {
  // Regex to identify potential math expressions more complex than simple prices
  const regex = /\$(\s*$.*?$|\s*[0-9]+(\s*[\+\-\=\*\/]\s*[0-9]+)+\s*)/g

  // This function wraps identified expressions in double dollars
  // Assumes that expressions caught by regex are math expressions
  return text.replace(regex, (match) => {
    // Check to avoid double-wrapping if already wrapped
    if (!match.startsWith('$$') && !match.endsWith('$$')) {
      return `$$${match.substring(1, match.length)}$$`
    }
    return match // Return match if it's already correctly wrapped
  })
}

var selectors = {}
export function memo(this, dependenciesFn) {
  return function (this, target, key, descriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function (this, ...args) {
      selectors[key] =
        selectors[key] ??
        createSelector(function (this_, ...args) {
          return originalMethod.apply(this_, args)
        }, dependenciesFn.bind(this))
      return this._in_action
        ? originalMethod.apply(this, args)
        : selectors[key](this, ...args)
    }

    return descriptor
  }
}

import fileDownload from 'js-file-download'

export function download(url: string, filename: string = 'image.png') {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export function url_to_media_type(file) {
  const mediaTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',
    svg: 'image/svg+xml',
    tiff: 'image/tiff',
    ico: 'image/x-icon',
  }

  return (
    mediaTypes[file.split('.').at(-1).toLowerCase()] ||
    'application/octet-stream'
  )
}

export function is_vision_model(model) {
  return [
    'google/gemini-1.5-pro',
    'openai/gpt-4-vision-preview',
    'openai/gpt-4',
    'anthropic/claude-3-haiku',
    'anthropic/claude-3-opus',
    'anthropic/claude-3-sonnet',
  ].includes(model)
}
