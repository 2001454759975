import { default as the_store } from './Store'
import posthog from 'posthog-js'
import ReactGA from 'react-ga4'
import consola from 'consola'
//note that this has to be common_init bc server loads this file, confusing af, need common dir
import { hny, _global } from './common_init'

export function lg(name, data = {}, store = the_store, now = false) {
  // console.log(`logging name: `, name);
  if (now) {
    var oldBatchSizeTrigger = hny.batchSizeTrigger
    hny.batchSizeTrigger = 1
  }
  var D = {
    ...(store.user?.secure?.plan ?? {}),
    ...(store.user?.secure?.params ?? {}),
    plan_name: store.plan_always?.name,
    team_id: store.team?.id,
    user_id: store.user?.id,
    // message_count: Object.keys(store.messages ?? {}).length,
    // room_count: Object.keys(store.rooms ?? {}).length,
    user_email: store.user?.email,
    plan: store.plan_always,
    ...data,
  }
  D.name = name
  D.os = _global.os
  D.desktop = _global.desktop
  D.host = store.user?.host
  var event = hny.newEvent()
  event.add(D)
  event.send()
  if (now) hny.flush()
  hny.batchSizeTrigger = oldBatchSizeTrigger

  if (name !== 'action') {
    //@ts-ignore
    ReactGA.event?.({
      category: 'log',
      action: name,
      label: data.action_name,
      // params: {
      //   userId: store.user?.id,
      //   teamId: store.team?.id,
      //   ...data,
      // }
    })
    posthog.capture?.(name, D)
  }
}
