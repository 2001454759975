import { css } from '@stitches/core'
import { generateClassNames } from '@supabase/auth-ui-shared'
import { Appearance } from '../../types'

/* border: 0px !important; */
/* background-color: #f9f9f9 !important; */
/* padding: 8px !important; */
/* placeholder: #9ca3af !important; */
/* placeholder-color: #9ca3af !important; */
const inputDefaultStyles = css({
  fontFamily: '$inputFontFamily',
  background: '$inputBackground',
  borderRadius: '4px',
  padding: '8px',
  cursor: 'text',
  borderWidth: '1px',
  borderColor: '#e0e0e0',
  borderStyle: 'solid',
  fontSize: '$baseInputSize',
  width: '100%',
  color: '$inputText',
  boxSizing: 'border-box',
  '&:hover': {
    borderColor: '#f9f9f9',
    outline: 'none',
  },
  '&:focus': {
    borderColor: '#e0e0e0',
    outline: 'none',
  },
  '&::placeholder': {
    color: '$inputPlaceholder',
    letterSpacing: 'initial',
  },
  transitionProperty: 'background-color, border',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '100ms',
  variants: {
    type: {
      default: {
        letterSpacing: '0px',
      },
      password: {
        letterSpacing: '6px',
      },
    },
  },
})

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode
  type: 'text' | 'password' | 'email'
  appearance?: Appearance
}

const Input: React.FC<InputProps> = ({ children, appearance, ...props }) => {
  const classNames = generateClassNames(
    'input',
    inputDefaultStyles({
      type: props.type === 'password' ? 'password' : 'default',
    }),
    appearance,
  )

  return (
    <input
      {...props}
      style={appearance?.style?.input}
      className={classNames.join(' ')}
    >
      {children}
    </input>
  )
}

export { Input }
