import React from 'react'
import { Button } from '@/components/ui/button'

export const HoldToConfirmButton = ({
  onClick = null,
  onCancelledClick = null,
  holdDelay = 2500,
  children,
  ...props
}) => {
  const [percentage, setPercentage] = React.useState(0)
  const startTime = React.useRef(null)
  const holdIntervalRef = React.useRef(null)

  React.useEffect(() => {
    return () => {
      holdIntervalRef.current && clearInterval(holdIntervalRef.current)
    }
  }, [])

  const startCounter = (e) => {
    if (holdIntervalRef.current) {
      return
    }
    startTime.current = Date.now()
    holdIntervalRef.current = setInterval(() => {
      if (startTime.current) {
        setPercentage(
          Math.floor(((Date.now() - startTime.current) / holdDelay) * 100),
        )
        if (Date.now() - startTime.current > holdDelay) {
          stopCounter(e)
          onClick?.(e)
        } else {
          onCancelledClick?.(e)
        }
      }
    }, 10)
  }

  const stopCounter = (e) => {
    startTime.current = null
    setPercentage(0)
    if (holdIntervalRef.current) {
      clearInterval(holdIntervalRef.current)
      holdIntervalRef.current = null
    }
  }

  return (
    <span className="shrink-0 bg-gray-200">
      <Button
        type="button"
        onMouseDown={startCounter}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
        onTouchStart={startCounter}
        onTouchCancel={stopCounter}
        onTouchEnd={stopCounter}
        style={{
          background:
            percentage > 0
              ? 'linear-gradient(#333, #3a3a3a) no-repeat top left'
              : '',
          backgroundSize: `${percentage}%`,
        }}
        {...props}
      >
        {children}
      </Button>
    </span>
  )
}
