import Dexie from 'dexie'
import { createClient } from '@supabase/supabase-js'
import Sync from './sync'
import type { AppRouter } from '../server/hocus'

import superjson from 'superjson'

export const db: any = new Dexie('default')
db.version(2).stores({
  sessions: '++id, user_id, team_id',
})
;(window as any).db = db

import { createTRPCClient, httpBatchLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
console.log(`import.meta.env.VITE_API_URI`, import.meta.env.VITE_API_URI)

export const api = createTRPCReact<AppRouter>()
export const api_client = api.createClient({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_API_URI + '/api/',
      headers: () => {
        return {
          Authorization: `Bearer ${Sync.session.access_token}`,
        }
      },
    }),
  ],
})

window.api = api

export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URI,
  import.meta.env.VITE_SUPABASE_API_KEY,
)
;(window as any).supabase = supabase
