import { cookies, _global, parseValue } from './common_init'
export * from '../src/common_init'
import * as Sentry from '@sentry/react'
import Sync from './sync'
import { parse } from 'csv-parse'
import { consola, createConsola } from 'consola'
import ReactGA from 'react-ga4'
ReactGA.initialize('AW-11465219128')

// raw, original url params
const raw_url_params = {}

console.dump = console.log

export function getURLParams() {
  const params = new URLSearchParams(window.location.search)
  const cleanParams = {}

  for (let [key, value] of params) {
    if (key.endsWith('__stick')) {
      key = key.slice(0, -7)
      cookies.set('q__' + key, value)
    }
    if (key.endsWith('__clear')) {
      key = key.slice(0, -7)
      cookies.remove('q__' + key)
    }
    raw_url_params[key] = value

    // if (key == 'f') {
    //   cookies.set('FL', Base64.decode(value))
    //   const parser = new Parser()
    //   parser.write(overrides)
    //   parser.end()
    // }

    let parsedValue = parseValue(value)
    cleanParams[key] = parsedValue
  }

  return cleanParams
}

_global.url_params = getURLParams()

if (!Array.prototype.at) {
  Object.defineProperty(Array.prototype, 'at', {
    value: function (index) {
      // If the index is negative, count from the end of the array.
      index = Math.sign(index) < 0 ? this.length + index : index
      // Return the item if index is within the bounds of the array; undefined otherwise.
      return index >= 0 && index < this.length ? this[index] : undefined
    },
    writable: true,
    enumerable: false,
    configurable: true,
  })
}

Sentry.init({
  dsn: 'https://23cfb64a37947b1c4c16d7ddd0da39ca@o4505670493470720.ingest.sentry.io/4505670495305728',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://api.vello.ai',
        'https://vello.ai',
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (import.meta.env.DEV) {
  consola.level = 3
} else {
  consola.level = 1 // 2 is for warnings and errors
}

console.info('Vello there!')
