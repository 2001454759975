import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import store from '@/Store'

import { cn } from '@/lib/utils'

const TooltipProvider = TooltipPrimitive.Provider

// const Tooltip = TooltipPrimitive.Root
const Tooltip = ({ children, ...props }) => {
  // Assuming store.is_mobile is a boolean that tells you if the device is mobile or not.
  if (store.is_mobile) {
    // If it's a mobile device, just return the children without the tooltip wrapper.
    return <div {...props}>{children}</div>
  } else {
    // If it's not a mobile device, return the children within the TooltipPrimitive.Root.
    return <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>
  }
}

// const TooltipTrigger = TooltipPrimitive.Trigger
const TooltipTrigger = ({ children, ...props }) => {
  // Assuming store.is_mobile is a boolean that tells you if the device is mobile or not.
  if (store.is_mobile) {
    // If it's a mobile device, just return the children without the tooltip wrapper.
    return <div {...props}>{children}</div>
  } else {
    // If it's not a mobile device, return the children within the TooltipPrimitive.Root.
    return (
      <TooltipPrimitive.Trigger {...props}>{children}</TooltipPrimitive.Trigger>
    )
  }
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) =>
  store.is_mobile ? null : (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      alignOffset={210}
      align={align}
      className={cn(
        'z-50 overflow-hidden rounded-sm font-medium bg-gray-500 px-3 py-2 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  ),
)
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
