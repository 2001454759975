import Libhoney from 'libhoney'

import { consola, createConsola } from 'consola'
import posthog from 'posthog-js'

import Cookies from 'universal-cookie'
export const cookies = new Cookies()
// consola.wrapConsole()

export const hny = new Libhoney({
  writeKey: '1tG4qTPxcfYuh7FHcj7yFC',
  dataset: 'vello',
  batchTimeTrigger: 3000,
  batchSizeTrigger: 300,
  // disabled: true // uncomment for testing or development.
})

export const _global: any =
  typeof window !== 'undefined'
    ? window
    : typeof global !== 'undefined'
      ? global
      : ({} as any)

//S == server == node
_global.S = typeof window == 'undefined'

//@ts-ignore
_global.desktop = false
_global.os = 'node'
_global.original_url = _global.location?.pathname + _global.location?.search

if (!_global.S) {
  if (navigator.userAgent.toLowerCase().indexOf('electron') > -1) {
    _global.desktop = true
    if (navigator.userAgent.toLowerCase().indexOf('vello-desktop/') > -1)
      _global.desktop_version = navigator.userAgent
        .split('vello-desktop/')[1]
        .split(' ')[0]
  }
  var OSName = 'unknown'
  if (navigator.platform.indexOf('Win') !== -1) {
    OSName = 'windows'
  } else if (navigator.userAgent.indexOf('Android') !== -1) {
    OSName = 'android'
  } else if (
    navigator.userAgent.indexOf('iPad') !== -1 ||
    navigator.userAgent.indexOf('iPhone') !== -1
  ) {
    OSName = 'ios'
  } else if (navigator.platform.indexOf('Mac') !== -1) {
    OSName = 'mac'
  } else if (navigator.platform.indexOf('Linux') !== -1) {
    OSName = 'linux'
  }
  _global.os = OSName
  _global.mobile = OSName == 'ios' || OSName == 'android'
  _global.web = !_global.desktop && !_global.mobile
}

export function parseValue(value) {
  if (value === 'control') return undefined
  if (value === null || value === undefined) return value
  if (value === 'null') return null
  if (value === 'undefined') return undefined
  if (typeof value === 'object') return value
  if (typeof value === 'boolean') return value
  if (value.toLowerCase() === 'on') return true
  if (value.toLowerCase() === 'off') return false
  if (value.toLowerCase() === 'true') return true
  if (value.toLowerCase() === 'false') return false

  const numValue = parseFloat(value)
  if (!isNaN(numValue)) return numValue

  return value
}

//===Posthog Feature Flags
if (_global.S) {
  // Server-side
  const { PostHog } = await import('posthog-node')
  _global.posthog = new PostHog(
    'phc_EyvRVyxYWfzUnhXODv8KF3vi8SMRgmzYQQ5Hz4fTDyA',
    { host: 'https://us.posthog.com' },
  )
} else {
  // Client-side
  posthog.init('phc_EyvRVyxYWfzUnhXODv8KF3vi8SMRgmzYQQ5Hz4fTDyA', {
    api_host: 'https://app.posthog.com',
  })
  _global.posthog = posthog
}

export function PH(key, user_id = null, options = {}) {
  if (_global.S) {
    //log exposure
    _global.posthog.getFeatureFlag(key, user_id, options)
    //return sync
    return _global.stores[user_id]?._FL[key]
  }
  return _global.posthog.getFeatureFlag(key, options)
}
_global.PH = PH

if (_global.S) {
  _global.url_params = {}
}

export function FL(key, def = null, user_id = _global.uid, options: any = {}) {
  const cook = options.cookies ?? cookies
  return key in _global.url_params
    ? _global.url_params[key]
    : cook.get('q__' + key) ?? parseValue(PH(key, user_id, options)) ?? def
}

_global.FL = FL
